import React from "react";
import '../styles/navbar.css'
export const Navigation = (props) => {
  return (
    <nav>
  <input id="nav-toggle" type="checkbox" />
  <div className="logo">
    <strong>Tracking</strong>
  </div>
  {/* <ul className="links">
   
    <li>
      <a href="#about">About</a>
    </li>
    <li>
      <a href="#work">Work</a>
    </li>
    
    <li>
      <a href="#contact">Contact</a>
    </li>
  </ul> */}
  <label htmlFor="nav-toggle" className="icon-burger">
    <div className="line" />
    <div className="line" />
    <div className="line" />
  </label>
</nav>

  );
};
