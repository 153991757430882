import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";



import { About } from "./components/about";

import { Header } from "./components/header";
import { Contact } from "./components/contact";

// index.js or App.js


import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
  
  }, []);

  return (
    <div>
      <Navigation />
      <Header  />
      
    
      {/* <Contact data={landingPageData.Contact} /> */}
    </div>
  );
};

export default App;
