import React , { useState }  from "react";
import axios from "axios";
import moment from "moment";
import 'moment/locale/fr';

import '../styles/global.css'
export const Header = () => {
 
  const [searchTerm, setSearchTerm] = useState('');
  const [data ,setData]=useState([])
  
  const shouldRenderSection = data && data.length > 0;
  const isLivrate = shouldRenderSection && data[0].is_livrate;
  moment.locale('fr');
  // Only format the date if the data is available and has the required field
  const date = shouldRenderSection ? new Date(data[0]?.departure_date) : null;


  const Arivaldate = shouldRenderSection && data[0]?.arrival_date ? new Date(data[0].arrival_date) : null;

  const formattedDate = date ? moment(date).format('LL') : '';
  const formattedTime = date ? moment(date).format('LTS') : '';


  const formattedDateArival = Arivaldate ? moment(Arivaldate).format('LL') : '';
  const formattedTimeArival = Arivaldate ? moment(Arivaldate).format('LTS') : '';

  const Checkpoints=data?.[0]?.checkpoints || []


  const groupedCheckpoints = Checkpoints.reduce((acc, checkpoint) => {
    const rawDate = moment(checkpoint.timestamp).format('YYYY-MM-DD');
    if (!acc[rawDate]) {
      acc[rawDate] = [];
    }
    acc[rawDate].push(checkpoint);
    return acc
  }, {});

  console.log('checkpoint',groupedCheckpoints)
  console.log('search',searchTerm)
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .get('https://jandf-trucking.com/api/routes', {
        params: {
          identifier: searchTerm,
        },
      })
      .then((response) => {
        console.log(response.data); // Handle the response data here
        setData(response.data)
      })
      .catch((error) => {
        console.error('There was an error making the request!', error);
      });
  };
  return (
    <>
  <section  className=" border-dark bg-light py-5 mt-5 ">
    <div className="container-fluid border-dark bg-light py-5 mt-5 ">
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-8 col-lg-6 mt-5">
          <div  className="d-flex justify-content-center" > <p className="card-text text fw-bold fs-4"> SUIVEZ VOTRE COLIS</p></div>
          <form className="d-flex justify-content-center mt-5 w-100" role="search" onSubmit={handleSubmit}>
            <input
              className="form-control py-5 me-4"
              type="search"
              placeholder="Taper l'Id du colis"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              aria-label="Search"
              style={{ flexGrow: 1, fontSize: "1.5rem" }}  // Increase text size
            />
            <button
              className="btn btn-outline-info btn-info text-white py-3 px-5"
              type="submit"
              style={{ fontSize: "1.5rem" }}  // Increase text size
            >
              Suivre
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
  {shouldRenderSection && (
  <section className=" border-dark bg-light py-5">
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12 col-md-8">
          <div className="card" style={{ maxWidth: 1250 }}>
            <div className="row">
              <div className="col-md-8">
                <div className="card-body">
                  <p className="card-text">Code de suivi :{data?.[0]?.ref_code || ''} </p>
                  <p className="card-text">
                    Cet envoie est traite par :{" "}
                    <span className="fw-bold">Tracking</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="card py-4" style={{ maxWidth: 1250 }}>
              <div className="row">
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text text-success fs-3">
                    {isLivrate ? 'Livraison effectuée' : 'Livraison en cours'}
                    </p>
                    {}
                    <p className="card-text">
                      {formattedDate} à {formattedTime}
                    </p>
                    <p className="text fw-bold">
                    Origine Zone de Depart : {data?.[0]?.departure_location || ''}
                    </p>
                    {/* Barre de progression */}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-md-10 px-4">
                <div className="d-flex justify-content-center">
                  <div
                    className="progress"
                    style={{ width: "100%" }}
                    role="progressbar"
                    aria-label="Success striped example"
                    aria-valuenow={100}
                    aria-valuemin={100}
                    aria-valuemax={100}
                  >
                    <div
                        className={`progress-bar progress-bar-striped progress-bar-animated ${
                          isLivrate ? 'bg-success' : 'bg-secondary'
                        }`}
                        style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <p className="text-dark p-3 fw-bold">
                  Zone d'Arrivée :  {data?.[0]?.destination_location || ''} 
                </p>
                
              </div>
              <div className="d-flex justify-content-end">
               { Arivaldate &&(
                  <p className="card-text px-3">
                  {formattedDateArival} à {formattedTimeArival} 
                </p>
               )} 
              
              </div>
              
            </div>
            <div
              className="accordion"
              id="accordionExample"
              style={{ maxWidth: 1250 }}
            >
              <div className="accordion-item">
                <h2 className="accordion-header py-4">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <p className="text-dark fw-bold fs-3">
                      Toutes les mises a jour d'envoie
                    </p>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="timeline">
                    {Object.keys(groupedCheckpoints).map((rawDate) => (
                          <React.Fragment key={rawDate}>
                             <hr />
                            <li className="timeline-item">
                              <strong>
                                {moment(rawDate).format('dddd')} <br />{" "}
                                <span style={{ fontSize: 24 }}>{moment(rawDate).format('LL')}</span>
                              </strong>
                            </li>
                            {groupedCheckpoints[rawDate].map((checkpoint) => (
                              <li key={checkpoint.id} className="timeline-item">
                                <time>{moment(checkpoint.timestamp).format('HH:mm')} Heure locale</time>
                                <p>
                                  <strong>{checkpoint.description}</strong>
                                  <br />
                                  {checkpoint.location}
                                  <br />Colis :{" "}
                                  <a href="#" className="text-info">
                                    {checkpoint.ref_code}
                                  </a>
                                </p>
                              </li>
                            ))}
                          </React.Fragment>
                        ))}
                        
                    </ul>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )}
</>

  );
};
